import { JSX, useEffect, useMemo, useState } from 'react';
import { ImageBackground as NativeImageBackground } from 'react-native';
import imageThumbprint from '../../../assets/images/thumbprint.png';
import { Heading, Layout, Link, Text } from '../../components/core';
import { IconMantraLogoReversed } from '../../components/icons/IconMantraLogoReversed';
import { ImageMantraHealth } from '../../components/images/ImageMantraHealth';
import { PageContent, PageFooter } from '../../components/page';
import { getRoute, useCurrentRouteSearch } from '../../routes';
import { getStylesheet } from '../../styles';
import { setRedirectUrl } from '../../utils/authenticationStore';
import { AuthErrorSimple } from './components/AuthError';
import { PatientPortalInvalidInfo, PatientPortalReauthInfo } from './components/PatientPortalInfo';
import { SignupReauthenticate } from './components/SignupReauthenticate';
import { LoginForm } from './forms/LoginForm';

export const LoginPage = (): JSX.Element => {
  const searchParams = useCurrentRouteSearch();

  const [showDisabledError, setShowDisabledError] = useState(false);

  const showSignupReauth = searchParams.postSignup === 'true';
  const showPPLoginReauth = searchParams.reauth === 'true'; // patient portal needs re-authentication
  const showPPLoginInvalid = searchParams.invalidLogin === 'true'; // patient portal login failed

  const redirectUrl = useMemo(() => {
    const path = searchParams.to ?? getRoute('home', {});
    if (
      path.startsWith(getRoute('login', {})) ||
      path.startsWith(getRoute('logout', {})) ||
      path === getRoute('start', {})
    ) {
      // Avoid redirect loops.
      return getRoute('home', {});
    }

    return path;
  }, [searchParams]);

  useEffect(() => {
    if (redirectUrl === getRoute('home', {})) {
      // No reason to deeplink to home since that's the default.
      return;
    }

    void setRedirectUrl(redirectUrl);
  }, [redirectUrl]);

  const onUserDisabled = (): void => {
    setShowDisabledError(true);
  };

  return (
    <PageContent paddingTop={4} pageSize="medium" testID="login-page">
      <NativeImageBackground resizeMode="cover" source={imageThumbprint} style={{ width: '100%' }}>
        <Layout.VStack {...styles.titleWrapper} space={52} width="100%">
          <Layout.HStack space={4} alignItems="center">
            <IconMantraLogoReversed size={6} accessibilityHidden />

            <ImageMantraHealth height={18} reversed />
          </Layout.HStack>

          <Heading.h1 color="lightText">School-sponsored mental health care.</Heading.h1>
        </Layout.VStack>
      </NativeImageBackground>

      {showDisabledError && (
        <Layout.View {...styles.loginWrapper}>
          <AuthErrorSimple errorType="disabled" />
        </Layout.View>
      )}

      {!showDisabledError && (
        <Layout.VStack {...styles.loginWrapper} space={4}>
          <Heading.h3 level={1}>Log in</Heading.h3>

          {showSignupReauth && <SignupReauthenticate />}
          {showPPLoginReauth && <PatientPortalReauthInfo />}
          {showPPLoginInvalid && <PatientPortalInvalidInfo />}

          <LoginForm onDisabled={onUserDisabled} mrn={searchParams.mrn} />

          <Layout.Center>
            <Text.para>
              New to Mantra?{' '}
              <Link.para bold to={getRoute('signup', {})}>
                Sign up
              </Link.para>
            </Text.para>
          </Layout.Center>
        </Layout.VStack>
      )}

      <PageFooter />
    </PageContent>
  );
};

const styles = getStylesheet({
  titleWrapper: {
    padding: 6,
  },

  loginWrapper: {
    backgroundColor: 'white',
    padding: 6,
    shadow: 'medium',
  },
});
